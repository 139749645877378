import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { GeneralLayout } from 'components/layouts/general-layout';
import { NotFoundError } from 'components/placeholder-errors/not-found-error';
import { GeneralLayoutLoading } from 'components/placeholder-loadings/general-layout-loading';
import { lazyNamedExport } from 'utils/lazy-named-export';

const AuthRoutes = lazyNamedExport(
  import(/* webpackChunkName: "AuthRoutes" */ './Auth'),
  'AuthRoutes'
);
const LegalAssistantRoutes = lazyNamedExport(
  import(/* webpackChunkName: "LegalAssistantRoutes" */ './LegalAssistant'),
  'LegalAssistantRoutes'
);
const SettingRoutes = lazyNamedExport(
  import(/* webpackChunkName: "SettingRoutes" */ './Setting'),
  'SettingRoutes'
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="/*"
        element={
          <Suspense fallback={<GeneralLayoutLoading />}>
            <Routes>
              <Route path="/" element={<GeneralLayout />}>
                <Route
                  path="legal-assistant/*"
                  element={<LegalAssistantRoutes />}
                />
                <Route path="setting/*" element={<SettingRoutes />} />
                <Route
                  path=""
                  element={<Navigate to="/legal-assistant" replace />}
                />
                <Route path="*" element={<NotFoundError />} />
              </Route>
            </Routes>
          </Suspense>
        }
      />
    </Routes>
  );
};
