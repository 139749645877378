import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetAuthenticationsProviderLogout } from 'api/generated/services/auth/authentication/authentication';
import { useAuthStore } from 'store/auth';

export function useLogout() {
  const navigate = useNavigate();
  const resetAuth = useAuthStore((state) => state.actions.reset);
  const user = useAuthStore((state) => state.user);

  const { refetch: logoutDirect } = useGetAuthenticationsProviderLogout(
    'direct',
    {
      query: {
        enabled: false,
        onSuccess() {
          localStorage.clear();
          resetAuth();
          navigate('/');
        },
      },
    }
  );

  const logout = useCallback(async () => {
    if (!user) {
      navigate('/auth/login');
    }

    await logoutDirect();
  }, [logoutDirect, navigate, user]);

  return {
    logout,
  };
}
