import { Link } from 'react-router-dom';

import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';

import { AvatarDropdownMenuItemsProps } from './types';

export const AVATAR_DROPDOWN_MENU_ITEMS: (
  props: AvatarDropdownMenuItemsProps
) => MenuProps['items'] = ({ onClickLogout }) => [
  {
    key: 'pengaturan',
    label: <Link to="/setting/profile">Pengaturan Akun</Link>,
    icon: <SettingOutlined />,
  },
  {
    key: 'logout',
    label: 'Keluar',
    icon: <LogoutOutlined />,
    onClick: onClickLogout,
  },
];
