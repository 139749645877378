import { ThemeConfig } from 'antd';

const inputSizeObj = {
  controlHeight: 44,
  fontSize: 14,
};

export const theme: ThemeConfig = {
  hashed: false,
  token: {
    colorPrimary: '#007861',
    borderRadius: 8,
    fontFamily: 'Work Sans',
  },
  components: {
    Layout: {
      headerBg: '#fff',
    },
    Menu: {
      itemHoverBg: '#79c043',
      itemActiveBg: '#79c043',
      itemSelectedBg: '#79c043',
      itemHoverColor: '#fff',
      itemSelectedColor: '#fff',
      itemMarginInline: 0,
    },
    Button: {
      colorPrimary: '#007861',
      colorBorder: '#007861',
      colorText: '#79C043',
      controlHeight: 48,
    },
    Form: {
      colorTextLabel: '#404041',
      colorTextPlaceholder: '#BBC9D4',
      margin: 12,
    },
    Input: inputSizeObj,
    InputNumber: inputSizeObj,
    Select: inputSizeObj,
    DatePicker: inputSizeObj,
    Pagination: {
      itemActiveBg: '#007861',
      colorPrimaryActive: 'white',
    },
    Table: {
      fontSize: 16,
      fontWeightStrong: 500,
    },
    Typography: {
      colorLink: '#79c043',
    },
    Card: {
      colorBorderSecondary: '#e4e5e5',
    },
  },
};
