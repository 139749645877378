import { Layout } from 'antd';

import { GeneralLayout } from 'components/layouts/general-layout';

import { CenteredSpinnerLoading } from '../centered-spinner-loading';

export function GeneralLayoutLoading() {
  return (
    <GeneralLayout>
      <Layout.Content className="w-full">
        <CenteredSpinnerLoading />
      </Layout.Content>
    </GeneralLayout>
  );
}
