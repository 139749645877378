import { message } from 'antd';
import { isAxiosError } from 'axios';

import { SERVICES } from 'config/constants';
import { GenerateUrlPathProps } from 'types';

export function generateUrlPath(props: GenerateUrlPathProps) {
  const { service, path, version = 'v1' } = props ?? {};

  return `/${SERVICES[service]}/${version}/${path?.replace(/^\//, '')}`;
}

// Under development, will be adjusted once BE ready
export function errorHandler(error: unknown) {
  if (isAxiosError(error)) {
    return message.error(error.message);
  }
}

export function normalizeNumber(value: string) {
  return value.replace(/\D/g, '');
}

export const combinedInitialName = (firstName: string, lastName: string) => {
  const firstLetter = firstName ? firstName[0] : '';
  const secondLetter = lastName ? lastName[0] : '';
  return `${firstLetter}${secondLetter}`;
};

export function formatPhoneNumberPayloadBE(
  val: string | number | null | undefined
) {
  if (val === 0 || val === '0') return '+62';
  if (val === 62 || val === '62') return '+62';
  if (val && String(val)?.[0] === '0') {
    return `+62${String(val)?.substring(1, String(val)?.length)}`;
  }
  if (val && String(val)?.[0] === '6' && String(val)?.[1] === '2') {
    return `+62${String(val)?.substring(2, String(val)?.length)}`;
  }

  return `+62${val}`;
}
