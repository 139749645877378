import React from 'react';

import { Col, Layout, Row, Typography } from 'antd';

export function PageFooter() {
  return (
    <Layout.Footer className="bg-white border-t py-10">
      <Row gutter={[6, 6]} justify="space-between">
        <Col className="left-section" md={8} xs={24}>
          <Typography.Text className="text-sm text-hk-navy">
            © {new Date().getFullYear()} Hukumku
          </Typography.Text>
        </Col>
        <Col className="flex gap-4 justify-end" md={8} xs={24}>
          <Typography.Link
            className="text-sm text-hk-navy"
            href="https://www.hukumku.id/syarat-dan-ketentuan"
            target="_blank">
            Syarat dan Ketentuan
          </Typography.Link>
          <Typography.Link
            className="text-sm text-hk-navy"
            href="https://www.hukumku.id/kebijakan-privasi"
            target="_blank">
            Kebijakan Privasi
          </Typography.Link>
        </Col>
      </Row>
    </Layout.Footer>
  );
}
