import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';

export function CenteredSpinnerLoading() {
  return (
    <Row className="mt-36" justify="center">
      <Col>
        <Spin indicator={<LoadingOutlined />} size="large" />
      </Col>
    </Row>
  );
}
