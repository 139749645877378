interface LogOptions {
  error?: any;
  message?: string;
}

export function log({ error, message = "" }: LogOptions) {
  const severity = error ? "[ERROR]" : "[INFO]";

  if (process.env.NODE_ENV === "production") return;

  // eslint-disable-next-line no-console
  console.log(severity, message, { error });
}
