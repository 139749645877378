import { Result } from 'antd';

export function GeneralError() {
  return (
    <Result
      className="w-full h-full mt-32"
      status="500"
      subTitle="Sorry, something went wrong"
      title="500"
    />
  );
}
