import { Dropdown, Grid, Space } from 'antd';
import { useLogout } from 'hooks/useLogout';

import { Avatar } from 'components/avatar';
import { useCurrentUserStore } from 'store/current-user';

import { AVATAR_DROPDOWN_MENU_ITEMS } from '../constants';

export function AvatarDropdown() {
  const screens = Grid.useBreakpoint();
  const { firstName, lastName, picture } = useCurrentUserStore(
    (state) => state
  );
  const { logout } = useLogout();

  return (
    <Dropdown
      className="avatar-dropdown"
      menu={{
        items: AVATAR_DROPDOWN_MENU_ITEMS({
          onClickLogout: logout,
        }),
      }}
      trigger={['click']}>
      <Space>
        <Avatar
          size={screens.md ? 56 : 32}
          user={{
            first_name: firstName,
            last_name: lastName,
            picture: picture,
          }}
        />
      </Space>
    </Dropdown>
  );
}
