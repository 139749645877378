import { Result } from 'antd';

export function NotFoundError() {
  return (
    <Result
      className="w-full h-full"
      status="404"
      subTitle="Sorry, the page you visited does not exist."
      title="404"
    />
  );
}
