import { Link } from 'react-router-dom';

import { Button, Col, Grid, Layout, Row, Space } from 'antd';

import { ReactComponent as HukumkuLogo } from 'assets/svg/header-logo.svg';
import { ReactComponent as HukumkuLogoMobile } from 'assets/svg/header-logo-mobile.svg';
import { useAuthStore } from 'store/auth';

import { AvatarDropdown } from './components';

export function PageHeader() {
  const screens = Grid.useBreakpoint();
  const user = useAuthStore((state) => state.user);

  return (
    <Layout.Header className="h-24 border-b">
      <Row align="middle" className="h-full" justify="space-between">
        <Col>
          <Link to="/">
            <Row align="middle">
              {screens.md ? <HukumkuLogo /> : <HukumkuLogoMobile />}
            </Row>
          </Link>
        </Col>
        <Col>
          {user ? (
            <Space align="center" size={screens.md ? 35 : 14}>
              <AvatarDropdown />
            </Space>
          ) : (
            <Link to={'/auth/type'}>
              <Button type="primary">Gabung Sekarang</Button>
            </Link>
          )}
        </Col>
      </Row>
    </Layout.Header>
  );
}
